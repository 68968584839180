import React, {ReactNode, useCallback, useEffect, useState} from "react";
import {RouteProps} from "@/router/types";
import {Button, Col, Form, Input, Modal, Pagination, Row, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {fck, isPrimeType} from "@/utils/utils";
import {getBrokerEdrfBalance, getDataPairs, updateDataPairs} from "@/api/admin";
import {Pagenation} from "@/api/types";
import ErrorMessage from "@/components/ErrorMessage";
import {DerifyTradeModal} from "@/views/CommonViews/ModalTips";

const TokenData = () => {
  const [form] = Form.useForm();
  const [param,setParam] = useState<{title: string, param: string, token:string, value?:string}>({title: "", param:"", token: "", value: ""});
  const [visible,setVisible] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const showChangeParam = useCallback((param:{title: string, param: string, token:string, value?:string}) => {
    setVisible(true);
    setParam(param);
  },[]);

  const colunmsMap:{[key:string]:ReactNode|{title:ReactNode,render: (val:any,record:any) => ReactNode}} = {
    "token_pair": "交易对",
    "short_position_amount": {
      title: "持仓额(USDT)",
      render: (val:any,record:any) => <>
        <div>多：{fck(record.long_position_amount,0,2)}{record.token_pair.split("/")[1]}</div>
        <div>空：{fck(record.short_position_amount,0,2)}{record.token_pair.split("/")[1]}</div>
      </>
    },
    "short_open_max": {
      title: "开仓上限",
      render: (val:any,record:any) => <>
        <div>多：{fck(record.long_open_max,0,2)}{record.token_pair.split("/")[1]}</div>
        <div>空：{fck(record.short_open_max,0,2)}{record.token_pair.split("/")[1]}</div>
      </>
    },
    "short_close_max": {
      title: "平仓上限",
      render: (val:any,record:any) => <>
        <div>多：{fck(record.long_close_max,0,2)}{record.token_pair.split("/")[1]}</div>
        <div>空：{fck(record.short_close_max,0,2)}{record.token_pair.split("/")[1]}</div>
      </>
    },
    "position_change_fee_ratio": {
      title: "动仓费率",
      render: (val:any,record:any) => `${fck(record.position_change_fee_ratio,2,2)}%`
    },

    "short_position_mining_reward_ratio": {
      title: "持仓挖矿收益率",
      render: (val:any,record:any) => <>
        <div>多：{fck(record.long_position_mining_reward_ratio,2,2)}%</div>
        <div>空：{fck(record.short_position_mining_reward_ratio,2,2)}%</div>
      </>
    },

    "unrealized_profit": "未实现盈利(USDT)",
    "unrealized_loss": "未实现亏损(USDT)",
    "position_change_fee_k": {
      title: "动仓费率参数k",
      render: (val,record) => <>
        <p><span>k:{record.position_change_fee_k}</span></p>
        <p><Button type="primary" size={"small"} onClick={() => showChangeParam({
          title:"动仓费率参数k",
          param:"k",
          token: record.token_address,
          value: record.position_change_fee_k
        })
        }>修改</Button></p>
      </>
    },
    "position_change_fee_g": {
      title: "动仓费率参数G",
      render: (val,record) => <>
          <p><span>g:{record.position_change_fee_g}</span></p>
          <p><Button type="primary" size={"small"} onClick={() => showChangeParam({
            title:"动仓费率参数G",
            param:"G",
            token: record.token_address,
            value: record.position_change_fee_g
          })
          }>修改</Button></p>
      </>
    },
    "position_change_fee_ro": {
      title: "动仓费ρ",
      render: (val,record) => <>
            <p><span>ρ:{record.position_change_fee_ro}</span></p>
            <p><Button type="primary" size={"small"} onClick={() => showChangeParam({
              title:"动仓费ρ",
              param:"ρ",
              token: record.token_address,
              value: (record.position_change_fee_ro * 100).toString()
            })
            }>修改</Button></p>
      </>
    }
  };

  const columns: ColumnsType<any> = [];

  for (let name in colunmsMap) {
    const column:any = colunmsMap[name];

    columns.push({
      title: isPrimeType(column) ? column : column?.title,
      fixed: columns.length === 0 ? "left" : undefined,
      dataIndex: name,
      //width: "10%",
      key: name,
      render: (value, record, index) => isPrimeType(column) ? value : column?.render(value,record,index)
    });
  }

  const [records,setRecords] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadData = useCallback(() => {
    setLoading(true);
    getDataPairs().then((data) => {
      setRecords(data);
    }).catch(e => {

    }).finally(() => {
      setLoading(false);
    });
  }, [form]);


  const doSumit = useCallback((token,param,value) => {

    const paramMap:any = {"k":"position_change_fee_k", "G":"position_change_fee_g", "ρ":"position_change_fee_ro"}

    if(!paramMap[param]){
      setErrorMsg("参数不支持编辑");
      return;
    }

    let val = paramMap[param];
    if(param === "ρ"){
      val = parseFloat(val)/100;
    }

    updateDataPairs(token, val, value).then((res) => {
      if(res.success){
        setVisible(false);
        DerifyTradeModal.success({msg:"修改成功"});
        loadData();
      }else{
        setErrorMsg(res.msg);
      }
    }).catch(e => {
      setErrorMsg(e);
    }).finally();
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Row gutter={[0,20]} style={{flexDirection: "column"}}>
        <Col>
          <Table
            loading={loading}
            tableLayout={"fixed"}
            pagination={false}
            dataSource={records}
            columns={columns}
          />
        </Col>
      </Row>
      <Modal
        title={param.title}
        getContainer={false}
        visible={visible}
        okText={"确认修改"}
        onOk={() => doSumit(param.token, param.param, param.value)}
        onCancel={() => setVisible(false)}
      >
        <ErrorMessage msg={errorMsg} visible={!!errorMsg} onCancel={() => setErrorMsg("")}/>
        <Form>
          {param.param === "k" ? <Form.Item label={"k"}>
            <Input onChange={({target:{value}}) => {
              setParam(Object.assign({}, param, {value}));
            }} value={param.value} name={param.param} suffix={""}/>
          </Form.Item> : <></>}
          {param.param === "ρ" ? <Form.Item label={"ρ"} >
            <Input onChange={({target:{value}}) => {
            setParam(Object.assign({}, param, {value}));
          }} value={param.value} name={param.param} suffix={"%"}/>
          </Form.Item> : <></>}
          {param.param === "G" ? <Form.Item label={"G"}>
            <Input onChange={({target:{value}}) => {
            setParam(Object.assign({}, param, {value}));
          }} value={param.value} name={param.param} suffix={""}/>
          </Form.Item> : <></>}

        </Form>
      </Modal>
      </>
  );
};

export default TokenData;
