import React from "react";
import { Col, Row, Tabs } from "antd";
import SystemData from "@/views/system/tabs/SystemData";
import TokenData from "@/views/system/tabs/TokenData";
import "./index.less";

const { TabPane } = Tabs;
const System = () => {
  return (
    <Row
      gutter={[0, 20]}
      style={{ flexDirection: "column" }}
      className={"syetem-container"}
    >
      <Col>
        <Tabs defaultActiveKey={"all"}>
          <TabPane key={1} tabKey="all" tab={"全网数据"}>
            <SystemData />
          </TabPane>
          <TabPane key={2} tabKey="traders" tab={"交易对数据"}>
            <TokenData />
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
};

export default System;
