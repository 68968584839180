import React, {useState, useEffect, ReactNode} from "react";
import { Row, Col } from "antd";
import classNames from "classnames";
import { useIntl } from "react-intl";
import { _findIndex } from "@/utils/loash";
import { withRouter, RouteComponentProps } from "react-router-dom";

interface MenuProps extends RouteComponentProps {}
const menu: Array<{ path:string,label:ReactNode }> = [
  {path: "traders", label: "用户"},
  {path: "accounts", label: "账户"},
  {path: "positions", label: "持仓"},
  {path: "orders", label: "委托"},
  {path: "trade", label: "交易"},
  {path: "mining", label: "挖矿"},
  {path: "drf", label: "DRF"},
  {path: "bdrf", label: "bDRF"},
  {path: "broker", label: "经纪商"},
  {path: "system", label: "系统"},
];

const Menu: React.FC<MenuProps> = props => {
  const { location, history } = props;

  const { formatMessage } = useIntl();

  const [index, setIndex] = useState<number>(0);
  const activeRoute = (index: number): void => {
    history.push(`/${menu[index].path}`);
    setIndex(index);
  };

  const initMenu = () => {
    const currentPath: string = location.pathname.split("/")[1];
    const index = _findIndex(menu, o => o.path === currentPath);
    setIndex(Math.max(index, 0));
  };

  useEffect(() => {
    initMenu();
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Row align={"middle"} className="menu">
      {menu.map((item, i: number) => (
        <Col key={i}>
          <span
            onClick={() => activeRoute(i)}
            className={classNames("menu-item", { active: index === i })}
          >
            {item.label}
          </span>
        </Col>
      ))}
    </Row>
  );
};

export default withRouter(Menu);
