import React, {ReactNode, useCallback, useEffect, useState} from "react";
import { RouteProps } from "@/router/types";
import {Switch, Route, Redirect, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useQuery} from "@/hooks";
import moment from "moment";
import {Button, Col, Form, Input, Pagination, Row, Select, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {dateFormat, fck, isPrimeType} from "@/utils/utils";
import {type} from "os";
import {isElementOfType} from "react-dom/test-utils";
import {
  getExchangeHeldPositions,
  getExchangeOrderedPositions,
  getExchangeTraderBalanceList, getExchangeTradeRecords,
  getExchangeTraders
} from "@/api/admin";
import {Pagenation} from "@/api/types";
import {Token} from "@/utils/contractUtil";
const Trades = () => {
  const [form] = Form.useForm();

  const tradeTypeOptions = [
    {value:-1,label:"全部"},
    {value:0, label:"开仓-市价委托"},
    {value:1, label:"开仓-双向对冲"},
    {value:2, label:"开仓-限价委托"},
    {value:3, label:"平仓-止盈委托"},
    {value:4, label:"平仓-止损委托"},
    { value: 5, label: "平仓-自动减仓" },
    { value: 6, label: "平仓-强制平仓" },   // * 6-MandatoryLiquidationClose
    { value: 7, label: "平仓-单笔市价" },   // * 7-SingleClose
    { value: 8, label: "一键平仓对冲仓位" },   // * 8-AllCloseHedgePart
    { value: 9, label: "一键平仓非对冲仓位" },   // * 9-AllCloseLeftPart
  ];

  const compensationOptions = [
    {value: -1,label: "全部"},
    {value: 0,label: "有补偿"},
    {value: 1,label: "无补偿"}
  ];

  const tokenOptions = [
    {value: -1,label: "全部"},
    {value: 0,label: "BTC/USDT", token: Token.BTC},
    {value: 1,label: "ETH/USDT", token: Token.ETH}
  ];

  const sideOptions = [
    {value:-1, label:"全部"},
    {value:0 ,label:"多"},
    {value:1 ,label:"空"},
  ];


  const colunmsMap:{[key:string]:ReactNode|{title:ReactNode,render: (d:any) => ReactNode}} = {
    "token": {
      title: "交易对",
      render: (val) => tokenOptions.find(item => item.token === val)?.label
    },
    "side": {
      title: "方向",
      render:(val) => sideOptions.find(item => item.value === val)?.label
    },
    "type": {
      title: "类型",
      render: (val) => tradeTypeOptions.find(item => item.value === val)?.label
    },
    "pnl_usdt": "盈亏（U）",
    "price": "成交价格（U）",
    "size": "成交数量",
    "amount": "成交金额（U）",
    "trading_fee": "手续费（U）",
    "position_change_fee": "动仓费（U）",
    "pnl_bond": "分摊补偿（bDRF）",
    "event_time": {
      title: '成交时间',
      render: v => moment(v).format('YYYY-MM-DD HH:mm:ss')
    },
    "user": "用户地址",
  };

  const columns: ColumnsType<any> = [];

  for (let name in colunmsMap) {
    const column:any = colunmsMap[name];

    columns.push({
      title: isPrimeType(column) ? column : column?.title,
      fixed: columns.length === 0 ? "left" : undefined,
      dataIndex: name,
      //width: "10%",
      key: name,
      render: (value, record, index) => isPrimeType(column) ? (value === undefined || value === null ? '-' : value) : column?.render(value,record,index)
    });
  }

  const [pagenation,setPagenation] = useState<Pagenation>(new Pagenation());
  const [loading, setLoading] = useState(false);

  const loadData = useCallback((page = 1, pageSize = 10) => {
    const {tradeType,token, side,compensation, trader} = form.getFieldsValue();
    setLoading(true);
    getExchangeTradeRecords(
      tradeType === undefined ? -1 : tradeType , 
      token === undefined ? -1 : token , 
      side === undefined ? - 1: side,
      compensation === undefined ? -1 :compensation,
      trader||"all", page, pageSize).then((data) => {
      data.current = page;
      setPagenation(data);
    }).catch(e => {

    }).finally(() => {
      setLoading(false);
    });
  }, [form]);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Row gutter={[0,20]} style={{flexDirection: "column"}}>
      <Col>
        <Form layout={"inline"} form={form} initialValues={{tradeType: -1}}>
          <Form.Item name="tradeType" label="交易类型">
            <Select options={tradeTypeOptions}  style={{ width: 200 }}/>
          </Form.Item>
          <Form.Item name="token" label="交易对">
            <Select defaultValue={-1} options={tokenOptions}/>
          </Form.Item>
          <Form.Item name="side" label="方向">
            <Select defaultValue={-1} options={sideOptions}/>
          </Form.Item>
          <Form.Item name="compensation" label="分担补偿">
            <Select defaultValue={-1} options={compensationOptions}/>
          </Form.Item>
          <Form.Item name="trader" label="用户地址">
            <Input type={"text"}/>
          </Form.Item>
          <Form.Item>
            <Button type={"primary"} onClick={() => loadData()}>搜索</Button>
          </Form.Item>
        </Form>
      </Col>
      <Col>
        <Table
          loading={loading}
          tableLayout={"fixed"}
          pagination={false}
          dataSource={pagenation.records}
          columns={columns}
        />
      </Col>
      <Col flex={'100%'} style={{textAlign:"right"}}>
        <Pagination
          pageSize={pagenation.pageSize}
          current={pagenation.current}
          onChange={(page: number, pageSize?: number) => {
            loadData(page, pageSize || 10)
          }}
          total={pagenation.totalItems}/>
      </Col>
    </Row>
  );
};

export default Trades;
