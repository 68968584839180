import React, {ReactNode, useCallback, useEffect, useState} from "react";
import { RouteProps } from "@/router/types";
import {Switch, Route, Redirect, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useQuery} from "@/hooks";
import {Button, Col, Form, Input, Pagination, Row, Select, Table, Tabs} from "antd";
import {ColumnsType} from "antd/es/table";
import {dateFormat, fck, isPrimeType} from "@/utils/utils";
import {type} from "os";
import {isElementOfType} from "react-dom/test-utils";
import {
  getExchangeHeldPositions,
  getExchangeOrderedPositions, getExchangePmrBalanceList,
  getExchangeTraderBalanceList, getExchangeTradeRecords,
  getExchangeTraders
} from "@/api/admin";
import {Pagenation} from "@/api/types";
import {Token} from "@/utils/contractUtil";
import BrokerInfos from "@/views/broker/tabs/BrokerInfos";
import BrokerTraders from "@/views/broker/tabs/BrokerTraders";
import BrokerBalances from "@/views/broker/tabs/BrokerBalances";
import BrokerRewards from "@/views/broker/tabs/BrokerRewards";
import "./index.less"
const { TabPane } = Tabs;
const Broker = () => {
  const [form] = Form.useForm();
  const query = useQuery();



  useEffect(() => {
  }, []);

  return (
    <Row gutter={[0,20]} style={{flexDirection: "column"}} className={"broker-container"}>
      <Col>
        <Tabs defaultActiveKey={"info"}>
          <TabPane key={1} tabKey="info" tab={"经纪商信息"}>
            <BrokerInfos/>
          </TabPane>
          <TabPane key={2} tabKey="traders" tab={"旗下用户信息"}>
            <BrokerTraders/>
          </TabPane>
          <TabPane key={3} tabKey="rewards" tab={"收益流水"}>
            <BrokerRewards/>
          </TabPane>
          <TabPane key={4} tabKey="deposit" tab={"充值信息"}>
            <BrokerBalances/>
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
};

export default Broker;
