import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import IconFont from "@/components/IconFont";
import { ModalProps } from "antd/es/modal";
import { Button, Modal, Form, Input, Row, Col } from "antd";
import * as web3Utils from "@/utils/web3Utils";
import { TraderAccount, TransferOperateType } from "@/utils/types";
import { checkNumber, fck } from "@/utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { AppModel, RootStore, UserModel } from "@/store";
import ErrorMessage from "@/components/ErrorMessage";
import { showTransfer } from "@/store/modules/app";
import { DerifyTradeModal } from "@/views/CommonViews/ModalTips";

interface LoginProps extends ModalProps {
  onLoginSuccess: (token: string) => void;
}

const LoginModal: React.FC<LoginProps> = props => {
  const { visible } = props;
  const location = useLocation();
  const history = useHistory();

  const [form] = Form.useForm();

  const [errorMsg, setErrorMsg] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    (document as any).getElementById("root").className = "main-no-scroll";
  }, []);

  const doLogin = useCallback(() => {
    const { username, password } = form.getFieldsValue();
    if (!username || !password) {
      setErrorMsg("请输入用户名或密码");
      return;
    }
    setErrorMsg("");
    const loginAction = UserModel.actions.login(username, password);
    loginAction(dispatch)
      .then(data => {
        if (data.success) {
          (document as any).getElementById("root").className = "";
          DerifyTradeModal.success({ msg: "登录成功!" });
          props.onLoginSuccess(data.data);
        } else {
          setErrorMsg(data.msg);
        }
      })
      .catch(e => {
        setErrorMsg("系统异常，请稍后再试!");
      });
  }, [form]);

  return (
    <Modal
      wrapClassName="login-modal"
      title="登录"
      footer={null}
      visible={visible}
      closable={false}
      width={400}
      getContainer={false}
      focusTriggerAfterClose={false}
      {...props}
    >
      <Row>
        <Col flex="100%">
          <ErrorMessage
            msg={errorMsg}
            visible={!!errorMsg}
            onCancel={() => setErrorMsg("")}
          />
          <Form layout={"vertical"} form={form}>
            <Form.Item label="用户名" name="username">
              <Input type={"text"} />
            </Form.Item>
            <Form.Item label="密码" name="password">
              <Input type={"password"} />
            </Form.Item>
            <Form.Item>
              <Button
                size="large"
                shape="round"
                block
                type="primary"
                onClick={() => doLogin()}
              >
                登录
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default LoginModal;
