import React, {ReactNode, useCallback, useEffect, useState} from "react";
import { RouteProps } from "@/router/types";
import moment from "moment";
import {Switch, Route, Redirect, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useQuery} from "@/hooks";
import {Button, Col, Form, Input, Pagination, Row, Select, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {fck, isPrimeType} from "@/utils/utils";
import {type} from "os";
import {isElementOfType} from "react-dom/test-utils";
import {getExchangeTraders} from "@/api/admin";
import {Pagenation} from "@/api/types";
interface LoginProps extends RouteProps {}

const Traders = () => {
  const [form] = Form.useForm();
  const orderByOptions = [
    {value: 0,label: "注册时间"},
    {value: 1,label: "账户余额"},
    {value: 2,label: "保证金余额"},
    {value: 3,label: "持仓总额"},
    {value: 4,label: "保证金率"},
    {value: 5,label: "累计挖矿收益"},
    {value: 6,label: "质押DRF数"}
  ];
  const countryOptions = [
    {value: -1,label: "全部"},
    {value: 0,label: "中国"},
    {value: 1,label: "韩国"},
    {value: 2,label: "美国"},
  ];
  const countrys: any = {} 
  countrys['0'] = '中国';
  countrys['1'] = '韩国';
  countrys['2'] = '美国';

  const colunmsMap:{[key:string]:ReactNode|{title:ReactNode,render: (d:any) => ReactNode}} = {
    "trader": "用户地址",
    "broker": "所属经纪商",
    "account_balance": "账户余额（U）",
    "margin_balance": "保证金余额（U）",
    "total_position_amount":"持仓总额(U)",
    "margin_rate": {
      title: "保证金率",
      render: (val:any, record:any, index:number) => {
        return <span>{fck(record.margin_rate, 2,2)}%</span>
      }
    },
    "accumulated_trading_amount": "累积交易额（U）",
    "drf_balance": "质押DRF数",
    "bdrf_return_balance": "质押bDRF",
    "accumulated_position_mining_reward": "累积挖矿收益（U）",
    "register_time": {
      title: '注册时间',
      render: v => moment(v).format('YYYY-MM-DD HH:mm:ss')
    },
    "ip": {title: "最新访问IP所在地", render:(value:string,record:any,index:number) => <><div>{record.country_name}</div><div>{record.ip}</div></>}
  };

  const columns: ColumnsType<any> = [];

  for (let name in colunmsMap) {
    const column:any = colunmsMap[name];

    columns.push({
      title: isPrimeType(column) ? column : column?.title,
      fixed: columns.length === 0 ? "left" : undefined,
      dataIndex: name,
      //width: "10%",
      key: name,
      render: (value, record, index) => isPrimeType(column) ? value : column?.render(value,record,index)
    });
  }

  const [pagenation,setPagenation] = useState<Pagenation>(new Pagenation());
  const [loading, setLoading] = useState(false);

  const loadData = useCallback((page = 1, pageSize = 10) => {
    const { orderBy, country, trader} = form.getFieldsValue();
    console.log(orderBy,country, trader)
    setLoading(true);
    getExchangeTraders(orderBy|| 0, (country === undefined || country === -1) ? 'all' : countrys[country],trader||"all", page, pageSize).then((data) => {
      data.current = page;
      setPagenation(data);
    }).catch(e => {
      console.log(e);
    }).finally(() => {
      setLoading(false);
    });
  }, [form]);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Row gutter={[0,20]} style={{flexDirection: "column"}}>
      <Col>
        <Form layout={"inline"} form={form}>
          <Form.Item name="orderBy" label="排序规则">
            <Select defaultValue={0} options={orderByOptions}/>
          </Form.Item>
          <Form.Item name="country" label="来源国家">
            <Select defaultValue={-1} options={countryOptions}/>
          </Form.Item>
          <Form.Item name="trader" label="用户地址">
            <Input type={"text"}/>
          </Form.Item>
          <Form.Item>
            <Button type={"primary"} onClick={() => loadData()}>搜索</Button>
          </Form.Item>
        </Form>
      </Col>
      <Col>
        <Table
          loading={loading}
          tableLayout={"fixed"}
          pagination={false}
          dataSource={pagenation.records}
          columns={columns}
        />
      </Col>
      <Col flex={'100%'} style={{textAlign:"right"}}>
        <Pagination
          pageSize={pagenation.pageSize}
          current={pagenation.current}
          onChange={(page: number, pageSize?: number) => {
            loadData(page, pageSize || 10)
          }}
          total={pagenation.totalItems}/>
      </Col>
    </Row>
  );
};

export default Traders;
