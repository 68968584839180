import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { RouteProps } from "@/router/types";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useQuery } from "@/hooks";
import { Button, Col, Form, Input, Pagination, Row, Select, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { fck, isPrimeType } from "@/utils/utils";
import { type } from "os";
import { isElementOfType } from "react-dom/test-utils";
import { getExchangeTraderBalanceList, getExchangeTraders } from "@/api/admin";
import { Pagenation } from "@/api/types";
interface LoginProps extends RouteProps {}

const Accounts = () => {
  const [form] = Form.useForm();
  const feeTypeOptions = [
    { value: "-1", label: "全部" },
    { value: "0", label: "手续费" },
    { value: "1", label: "动仓费" },
    { value: "2", label: "盈亏" },
    { value: "4", label: "gas费" },
    { value: "5", label: "清算费用" },
    { value: "6", label: "系统补偿" },
    { value: "7", label: "系统亏损分摊" },
    { value: "100", label: "资金转入" },
    { value: "101", label: "资金转出" },
  ];

  const colunmsMap: {
    [key: string]:
      | ReactNode
      | { title: ReactNode; render: (d: any) => ReactNode };
  } = {
    // "id": "71445232-49a7-5ab5-9c4a-0653aed166d2",
    // "tx": "0x51772d0c0cdf07d16e301562594e535d9eaef8f3f48708b42a2edcf2b5f1399c",
    user: "用户地址",
    fee_type: {
      title: "类型",
      render: value =>
        feeTypeOptions.find(item => item.value === value.toString())?.label,
    },
    amount: "金额",
    event_time: {
      title: "时间",
      render: v => moment(v).format('YYYY-MM-DD HH:mm:ss')
    }
  };

  const columns: ColumnsType<any> = [];

  for (let name in colunmsMap) {
    const column: any = colunmsMap[name];
    columns.push({
      title: isPrimeType(column) ? column : column?.title,
      fixed: columns.length === 0 ? "left" : undefined,
      dataIndex: name,
      key: name,
      render: (value, record, index) =>
        isPrimeType(column) ? value : column?.render(value, record, index),
    });
  }

  const [pagenation, setPagenation] = useState<Pagenation>(new Pagenation());
  const [loading, setLoading] = useState(false);

  const loadData = useCallback(
    (page = 1, pageSize = 10) => {
      const { feeType, trader } = form.getFieldsValue();
      setLoading(true);
      getExchangeTraderBalanceList(
        feeType || -1,
        trader || "all",
        page,
        pageSize
      )
        .then(data => {
          data.current = page;
          setPagenation(data);
        })
        .catch(e => {})
        .finally(() => {
          setLoading(false);
        });
    },
    [form]
  );

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Row gutter={[0, 20]} style={{ flexDirection: "column" }}>
      <Col>
        <Form layout={"inline"} form={form}>
          <Form.Item name="feeType" label="类型">
            <Select defaultValue={"-1"} options={feeTypeOptions} />
          </Form.Item>
          <Form.Item name="trader" label="用户地址">
            <Input type={"text"} />
          </Form.Item>
          <Form.Item>
            <Button type={"primary"} onClick={() => loadData()}>
              搜索
            </Button>
          </Form.Item>
        </Form>
      </Col>
      <Col>
        <Table
          loading={loading}
          tableLayout={"fixed"}
          pagination={false}
          dataSource={pagenation.records}
          columns={columns}
        />
      </Col>
      <Col flex={"100%"} style={{ textAlign: "right" }}>
        <Pagination
          pageSize={pagenation.pageSize}
          current={pagenation.current}
          onChange={(page: number, pageSize?: number) => {
            loadData(page, pageSize || 10);
          }}
          total={pagenation.totalItems}
        />
      </Col>
    </Row>
  );
};

export default Accounts;
