import React, {ReactNode, useCallback, useEffect, useState} from "react";
import { RouteProps } from "@/router/types";
import {Switch, Route, Redirect, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useQuery} from "@/hooks";
import moment from "moment";
import {Button, Col, Form, Input, Pagination, Row, Select, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {fck, isPrimeType} from "@/utils/utils";
import {type} from "os";
import {isElementOfType} from "react-dom/test-utils";
import {getBrokerList, getExchangeHeldPositions, getExchangeTraders} from "@/api/admin";
import {Pagenation} from "@/api/types";
import {Token} from "@/utils/contractUtil";
interface LoginProps extends RouteProps {}

const BrokerInfos = () => {
  const [form] = Form.useForm();

  const orderByOptions = [
    {value:0, label:"注册时间"},
    {value:1, label:"累积收益"},
    {value:2, label:"今日收益"}
  ];

  const colunmsMap:{[key:string]:ReactNode|{title:ReactNode,render: (d:any) => ReactNode}} = {
    "broker": "经纪商地址",
    "name": "名称",
    "logo": "头像",
    "id": "编码",
    "introduction": {title: "介绍", render:(value:string,record:any,index:number) => (value||"").substring(0,60)+"..."},
    "accumulated_reward": "累积返佣收益（U）",
    "today_reward": "今日收益（U）",
    "valid_period_in_day": "剩余有效天数（天）",
    "register_time": {
      title: "注册时间",
      render: v => moment(v).format('YYYY-MM-DD HH:mm:ss')
    }
  };

  const columns: ColumnsType<any> = [];

  for (let name in colunmsMap) {
    const column:any = colunmsMap[name];

    columns.push({
      title: isPrimeType(column) ? column : column?.title,
      fixed: columns.length === 0 ? "left" : undefined,
      dataIndex: name,
      //width: "10%",
      key: name,
      render: (value, record, index) => isPrimeType(column) ? value : column?.render(value,record,index)
    });
  }

  const [pagenation,setPagenation] = useState<Pagenation>(new Pagenation());
  const [loading, setLoading] = useState(false);

  const loadData = useCallback((page = 1, pageSize = 10) => {
    const {orderBy, trader} = form.getFieldsValue();
    setLoading(true);
    getBrokerList(orderBy||0,trader||"all", page, pageSize).then((data) => {
      data.current = page;
      setPagenation(data);
    }).catch(e => {

    }).finally(() => {
      setLoading(false);
    });
  }, [form]);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Row gutter={[0,20]} style={{flexDirection: "column"}}>
      <Col>
        <Form layout={"inline"} form={form}>
          <Form.Item name="orderBy" label="排序规则">
            <Select defaultValue={0} options={orderByOptions}/>
          </Form.Item>
          <Form.Item name="trader" label="用户地址">
            <Input type={"text"}/>
          </Form.Item>
          <Form.Item>
            <Button type={"primary"} onClick={() => loadData()}>搜索</Button>
          </Form.Item>
        </Form>
      </Col>
      <Col>
        <Table
          loading={loading}
          tableLayout={"fixed"}
          pagination={false}
          dataSource={pagenation.records}
          columns={columns}
        />
      </Col>
      <Col flex={'100%'} style={{textAlign:"right"}}>
        <Pagination
          pageSize={pagenation.pageSize}
          current={pagenation.current}
          onChange={(page: number, pageSize?: number) => {
            loadData(page, pageSize || 10)
          }}
          total={pagenation.totalItems}/>
      </Col>
    </Row>
  );
};

export default BrokerInfos;
