import {
  createStore,
  applyMiddleware,
  compose,
  combineReducers, Dispatch,
} from "redux";

import thunkMiddleware from "redux-thunk";
import AppModel, { AppState } from "./modules/app";
import UserModel, { UserState } from "./modules/user";
export interface RootStore {
  app: AppState,
  user: UserState
}

const composeEnhancers =
  typeof window === "object" &&
  // @ts-ignore
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? // @ts-ignore
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const middlewares = [thunkMiddleware];

if (process.env.NODE_ENV === "development") {
  middlewares.push(require("redux-logger").createLogger());
}

const rootStore  = {
  app: AppModel.reducers,
  user: UserModel.reducers,
};

const store = createStore(
  combineReducers(rootStore),
  composeEnhancers(applyMiddleware(...middlewares))
);

export {
  AppModel,
  UserModel
}

export default store;
