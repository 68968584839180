import React, { useCallback, useEffect, useState } from "react";
import { RouteProps } from "@/router/types";
import { Switch, Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@/hooks";
import LoginModal from "@/views/CommonViews/LoginModal";
import { RootStore } from "@/store";
import { DerifyTradeModal } from "@/views/CommonViews/ModalTips";
interface LoginProps extends RouteProps {}

const Login: React.FC<LoginProps> = props => {
  const dispatch = useDispatch();
  const query = useQuery();
  const redirect = query.redirect;
  const { routes, history, location } = props;
  const { isLogin } = useSelector((state: RootStore) => state.user);

  const onLoginSuccess = useCallback(
    token => {
      if (redirect) {
        history.push(redirect as any);
      } else {
        history.push('/traders');
      }
    },
    [history]
  );

  useEffect(() => {
    if (isLogin) {
      DerifyTradeModal.success({ msg: "登录成功,跳转到上一访问页面!" });
      setTimeout(() => history.go(-1), 1000);
    }
  }, [location]);

  return <LoginModal visible={!isLogin} onLoginSuccess={onLoginSuccess} />;
};

export default Login;
