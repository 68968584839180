import React, {ReactNode, useCallback, useEffect, useState} from "react";
import { RouteProps } from "@/router/types";
import {Switch, Route, Redirect, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useQuery} from "@/hooks";
import moment from 'moment'
import {Button, Col, Form, Input, Pagination, Row, Select, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {dateFormat, fck, isPrimeType} from "@/utils/utils";
import {type} from "os";
import {isElementOfType} from "react-dom/test-utils";
import {
  getExchangeHeldPositions,
  getExchangeOrderedPositions, getExchangePmrBalanceList,
  getExchangeTraderBalanceList, getExchangeTradeRecords,
  getExchangeTraders
} from "@/api/admin";
import {Pagenation} from "@/api/types";
import {Token} from "@/utils/contractUtil";
const Mining = () => {
  const [form] = Form.useForm();

  const typeOptions = [
    {value:-1,label:"全部"},
    {value:0, label:"收入"},
    {value:1, label:"提现"},
  ];

  const tokenOptions = [
    {value: "all",label: "全部"},
    {value: Token.BTC,label: "BTC/USDT"},
    {value: Token.ETH,label: "ETH/USDT"}
  ];

  const sideOptions = [
    {value:-1, label:"全部"},
    {value:0 ,label:"多"},
    {value:1 ,label:"空"},
  ];


  const colunmsMap:{[key:string]:ReactNode|{title:ReactNode,render: (d:any) => ReactNode}} = {
    "pmr_update_type": {
      title: "类型",
      render:(val) => typeOptions.find(it => it.value === val)?.label
    },
    "amount": "金额（U）",
    "event_time": {
      title: "时间",
      render: v => moment(v).format('YYYY-MM-DD HH:mm:ss')
    },
    "user": "用户地址",
  };

  const columns: ColumnsType<any> = [];

  for (let name in colunmsMap) {
    const column:any = colunmsMap[name];

    columns.push({
      title: isPrimeType(column) ? column : column?.title,
      fixed: columns.length === 0 ? "left" : undefined,
      dataIndex: name,
      //width: "10%",
      key: name,
      render: (value, record, index) => isPrimeType(column) ? value : column?.render(value,record,index)
    });
  }

  const [pagenation,setPagenation] = useState<Pagenation>(new Pagenation());
  const [loading, setLoading] = useState(false);

  const loadData = useCallback((page = 1, pageSize = 10) => {
    const {type,token, side,compensation, trader} = form.getFieldsValue();
    setLoading(true);
    getExchangePmrBalanceList(type||-1,trader||"all", page, pageSize).then((data) => {
      data.current = page;
      setPagenation(data);
    }).catch(e => {

    }).finally(() => {
      setLoading(false);
    });
  }, [form]);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Row gutter={[0,20]} style={{flexDirection: "column"}}>
      <Col>
        <Form layout={"inline"} form={form}>
          <Form.Item name="type" label="类型">
            <Select defaultValue={-1} options={typeOptions}/>
          </Form.Item>
          <Form.Item name="trader" label="用户地址">
            <Input type={"text"}/>
          </Form.Item>
          <Form.Item>
            <Button type={"primary"} onClick={() => loadData()}>搜索</Button>
          </Form.Item>
        </Form>
      </Col>
      <Col>
        <Table
          loading={loading}
          tableLayout={"fixed"}
          pagination={false}
          dataSource={pagenation.records}
          columns={columns}
        />
      </Col>
      <Col flex={'100%'} style={{textAlign:"right"}}>
        <Pagination
          pageSize={pagenation.pageSize}
          current={pagenation.current}
          onChange={(page: number, pageSize?: number) => {
            loadData(page, pageSize || 10)
          }}
          total={pagenation.totalItems}/>
      </Col>
    </Row>
  );
};

export default Mining;
