import Layout from "@/views/layout";
import Home from "@/views/home";
import Login from "@/views/login";
import Traders from "@/views/trader";
import Accounts from "@/views/account";
import Positions from "@/views/positions";
import Orders from "@/views/order";
import Trades from "@/views/trade";
import Mining from "@/views/mining";
import DRF from "@/views/drf";
import BDRF from "@/views/bdrf";
import Broker from "@/views/broker";
import System from "@/views/system";

const routes: Array<any> = [
  {
    path: "/",
    component: Layout,
    routes: [
      {
        path: "/",
        exact: false,
        component: Home,
        routes: [
          {
            path: "/login",
            exact: false,
            component: Login,
          },
          {
            path: "/traders",
            exact: false,
            component: Traders,
          },
          {
            path: "/accounts",
            exact: false,
            component: Accounts,
          },
          {
            path: "/positions",
            exact: false,
            component: Positions,
          },
          {
            path: "/orders",
            exact: false,
            component: Orders,
          },
          {
            path: "/trade",
            exact: false,
            component: Trades,
          },
          {
            path: "/mining",
            exact: false,
            component: Mining,
          },
          {
            path: "/drf",
            exact: false,
            component: DRF,
          },
          {
            path: "/bdrf",
            exact: false,
            component: BDRF,
          },
          {
            path: "/broker",
            exact: false,
            component: Broker,
          },
          {
            path: "/system",
            exact: false,
            component: System,
          },
        ]
      }
    ],
  },
];

export default routes;
