import * as axio from "@/utils/request"
import { Pagenation } from "@/api/types";
import { getCurrentServerEndPoint } from "@/config";


/**
 *
 * @param api {string}
 * @return {string}
 */
function getAPIUrl(api) {
  return getCurrentServerEndPoint() + api;
}

/**
 *
 * @param userName:q
 * @param password
 * @return {Promise<ResponseData>}
 */
export async function login(userName, password) {
  const response = await axio.post(getAPIUrl("/user/login"), { userName, password });
  if (response.code === 0) {
    return { data: response.data.token, success: true, code: response.code, msg: response.msg }
  }

  return { data: null, success: false, code: response.code, msg: response.msg }
}

/**
 * 用户信息
 * @param orderBy
 * @param countyName
 * @param trader
 * @param page
 * @param size
 * @return {Promise<Pagenation>}
 */
export async function getExchangeTraders(orderBy = 0, countyName = 'all', trader = 'all', page = 1, size = 10) {
  const content = await axio.get(getAPIUrl(`/exchange/traders/${orderBy}/${countyName}/${trader}/${page - 1}/${size}`));

  let pagenation = new Pagenation();

  pagenation.current = page;
  pagenation.pageSize = size;
  pagenation.records = [];
  pagenation.totalPage = 0;
  if (content && content.data) {
    pagenation.records = content.data.records;
    pagenation.totalItems = content.data.totalItems;
    pagenation.totalPage = content.data.totalPages;
    return pagenation;
  }
}


/**
 * 账户信息
 * @param feeType
 * @param trader
 * @param page
 * @param size
 * @return {Promise<Pagenation>}
 */
export async function getExchangeTraderBalanceList(feeType, trader, page = 1, size = 10) {
  const content = await axio.get(getAPIUrl(`/exchange/trader_balance_list/${feeType}/${trader}/${page - 1}/${size}`));

  let pagenation = new Pagenation();

  pagenation.current = page;
  pagenation.pageSize = size;
  pagenation.records = [];
  pagenation.totalPage = 0;
  if (content && content.data) {
    pagenation.records = content.data.records;
    pagenation.totalItems = content.data.totalItems;
    pagenation.totalPage = content.data.totalPages;
    return pagenation;
  }
}

/**
 * 持仓信息
 * @param tokenAddr
 * @param side
 * @param leverage
 * @param trader
 * @param page
 * @param size
 * @return {Promise<Pagenation>}
 */
export async function getExchangeHeldPositions(tokenAddr, side, leverage, trader, page = 1, size = 10) {
  const content = await axio.get(getAPIUrl(`/exchange/held_positions/${tokenAddr}/${side}/${leverage}/${trader}/${page - 1}/${size}`));

  let pagenation = new Pagenation();

  pagenation.current = page;
  pagenation.pageSize = size;
  pagenation.records = [];
  pagenation.totalPage = 0;
  if (content && content.data) {
    pagenation.records = content.data.records;
    pagenation.totalItems = content.data.totalItems;
    pagenation.totalPage = content.data.totalPages;
    return pagenation;
  }
}

/**
 * 委托信息
 * @param orderType
 * @param tokenAddr
 * @param side
 * @param leverage
 * @param trader
 * @param page
 * @param size
 * @return {Promise<Pagenation>}
 */
export async function getExchangeOrderedPositions(orderType, tokenAddr, side, leverage, trader, page = 1, size = 10) {
  const content = await axio.get(getAPIUrl(`/exchange/ordered_positions/${orderType}/${tokenAddr}/${side}/${leverage}/${trader}/${page - 1}/${size}`));

  let pagenation = new Pagenation();

  pagenation.current = page;
  pagenation.pageSize = size;
  pagenation.records = [];
  pagenation.totalPage = 0;
  if (content && content.data) {
    pagenation.records = content.data.records;
    pagenation.totalItems = content.data.totalItems;
    pagenation.totalPage = content.data.totalPages;
    return pagenation;
  }
}

/**
 * 交易信息
 * @param tradeType
 * @param tokenNo
 * @param side
 * @param compensation
 * @param trader
 * @param page
 * @param size
 * @return {Promise<Pagenation>}
 */
export async function getExchangeTradeRecords(tradeType, tokenNo, side, compensation, trader, page = 1, size = 10) {
  const content = await axio.get(getAPIUrl(`/exchange/trade_records/${tradeType}/${tokenNo}/${side}/${compensation}/${trader}/${page - 1}/${size}`));

  let pagenation = new Pagenation();

  pagenation.current = page;
  pagenation.pageSize = size;
  pagenation.records = [];
  pagenation.totalPage = 0;
  if (content && content.data) {
    pagenation.records = content.data.records;
    pagenation.totalItems = content.data.totalItems;
    pagenation.totalPage = content.data.totalPages;
    return pagenation;
  }
}

/**
 * 持仓挖矿
 * @param type
 * @param trader
 * @param page
 * @param size
 * @return {Promise<Pagenation>}
 */
export async function getExchangePmrBalanceList(type, trader, page = 1, size = 10) {
  const content = await axio.get(getAPIUrl(`/exchange/pmr_balance_list/${type}/${trader}/${page - 1}/${size}`));

  let pagenation = new Pagenation();

  pagenation.current = page;
  pagenation.pageSize = size;
  pagenation.records = [];
  pagenation.totalPage = 0;
  if (content && content.data) {
    pagenation.records = content.data.records;
    pagenation.totalItems = content.data.totalItems;
    pagenation.totalPage = content.data.totalPages;
    return pagenation;
  }
}

/**
 * DRF质押
 * @param type
 * @param trader
 * @param page
 * @param size
 * @return {Promise<Pagenation>}
 */
export async function getExchangeDrfBalanceList(type, trader, page = 1, size = 10) {
  const content = await axio.get(getAPIUrl(`/exchange/drf_balance_list/${type}/${trader}/${page - 1}/${size}`));

  let pagenation = new Pagenation();

  pagenation.current = page;
  pagenation.pageSize = size;
  pagenation.records = [];
  pagenation.totalPage = 0;
  if (content && content.data) {
    pagenation.records = content.data.records;
    pagenation.totalItems = content.data.totalItems;
    pagenation.totalPage = content.data.totalPages;
    return pagenation;
  }
}

/**
 * bDRF
 * @param type
 * @param trader
 * @param page
 * @param size
 * @return {Promise<Pagenation>}
 */
export async function getExchangebDrfBalanceList(type, trader, page = 1, size = 10) {
  const content = await axio.get(getAPIUrl(`/exchange/bdrf_balance_list/${type}/${trader}/${page - 1}/${size}`));

  let pagenation = new Pagenation();

  pagenation.current = page;
  pagenation.pageSize = size;
  pagenation.records = [];
  pagenation.totalPage = 0;
  if (content && content.data) {
    pagenation.records = content.data.records;
    pagenation.totalItems = content.data.totalItems;
    pagenation.totalPage = content.data.totalPages;
    return pagenation;
  }
}

/**
 * 经纪商信息
 * @param orderBy
 * @param broker
 * @param page
 * @param size
 * @return {Promise<Pagenation>}
 */
export async function getBrokerList(orderBy, broker, page = 1, size = 10) {
  const content = await axio.get(getAPIUrl(`/broker/list/${orderBy}/${broker}/${page - 1}/${size}`));

  let pagenation = new Pagenation();

  pagenation.current = page;
  pagenation.pageSize = size;
  pagenation.records = [];
  pagenation.totalPage = 0;
  if (content && content.data) {
    pagenation.records = content.data.records;
    pagenation.totalItems = content.data.totalItems;
    pagenation.totalPage = content.data.totalPages;
    return pagenation;
  }
}

/**
 * 经纪商 旗下用户信息
 * @param orderBy
 * @param broker
 * @param page
 * @param size
 * @return {Promise<Pagenation>}
 */
export async function getBrokerTraders(orderBy, broker, page = 1, size = 10) {
  const content = await axio.get(getAPIUrl(`/broker/traders/${orderBy}/${broker}/${page - 1}/${size}`));

  let pagenation = new Pagenation();

  pagenation.current = page;
  pagenation.pageSize = size;
  pagenation.records = [];
  pagenation.totalPage = 0;
  if (content && content.data) {
    pagenation.records = content.data.records;
    pagenation.totalItems = content.data.totalItems;
    pagenation.totalPage = content.data.totalPages;
    return pagenation;
  }
}

/**
 * 经纪商 收益信息
 * @param orderBy
 * @param broker
 * @param page
 * @param size
 * @return {Promise<Pagenation>}
 */
export async function getBrokerRewardBalance(orderBy, broker, page = 1, size = 10) {
  const content = await axio.get(getAPIUrl(`/broker/reward_balance/${orderBy}/${broker}/${page - 1}/${size}`));

  let pagenation = new Pagenation();

  pagenation.current = page;
  pagenation.pageSize = size;
  pagenation.records = [];
  pagenation.totalPage = 0;
  if (content && content.data) {
    pagenation.records = content.data.records;
    pagenation.totalItems = content.data.totalItems;
    pagenation.totalPage = content.data.totalPages;
    return pagenation;
  }
}

/**
 * 经纪商充值信息
 * @param broker
 * @param page
 * @param size
 * @return {Promise<Pagenation>}
 */
export async function getBrokerEdrfBalance(broker, page = 1, size = 10) {
  const content = await axio.get(getAPIUrl(`/broker/edrf_balance/${broker}/${page - 1}/${size}`));

  let pagenation = new Pagenation();

  pagenation.current = page;
  pagenation.pageSize = size;
  pagenation.records = [];
  pagenation.totalPage = 0;
  if (content && content.data) {
    pagenation.records = content.data.records;
    pagenation.totalItems = content.data.totalItems;
    pagenation.totalPage = content.data.totalPages;
    return pagenation;
  }
}



/**
 * 获取系统设置信息
 * @return {Promise<{gas_fee_pool: number, broker_reward_pool: number, total_unrealized_profit: number, total_edrf: number, theta_ratio: number, pmr_trading_fee_ratio: number, total_position_amount: number, margin_liquidation_ratio: number, drf_buyback_pool: number, insurance_trading_fee_ratio: number, insurance_pool: number, broker_trading_fee_ratio: number, total_unrealized_loss: number, total_margin_balance: number, margin_maintenance_ratio: number, bond_annual_interest_rate: number, position_mining_reward_pool: number, liquidity_pool: number, broker_apply_number: number, trading_fee_ratio: number, total_trading_amount: number, broker_valid_unit_number: number, total_usdt: number, total_bdrf: number}|*>}
 */
export async function getDataSystem() {
  const content = await axio.get(getAPIUrl(`/data/system`));

  if (content && content.data) {
    return content.data;
  }


  return {
    "drf_buyback_pool": 4019.16933932,
    "total_trading_amount": 295581.876861,
    "liquidity_pool": 226732.55560166,
    "insurance_pool": 4380.12993252,
    "gas_fee_pool": 6881.83094022,
    "position_mining_reward_pool": 32.18822932,
    "broker_reward_pool": 28.52399628,
    "total_usdt": 238055.2287,
    "total_bdrf": 34.63524625,
    "total_edrf": 2526100.0000000005,
    "total_position_amount": 51343.59953058,
    "total_unrealized_profit": 1790.76888677,
    "total_unrealized_loss": -2051.19355007,
    "total_margin_balance": 227093.51619485999,
    "margin_maintenance_ratio": 0.03,
    "margin_liquidation_ratio": 0.01,
    "theta_ratio": 0.2,
    "bond_annual_interest_rate": 0.1,
    "broker_apply_number": 60000,
    "broker_valid_unit_number": 600,
    "trading_fee_ratio": 0.0005,
    "pmr_trading_fee_ratio": 0.3,
    "insurance_trading_fee_ratio": 0.4,
    "broker_trading_fee_ratio": 0.3
  }
}

/**
 * 更新系统信息
 * @param params
 * @return {Promise<{msg: *, code, data: null, success: boolean}|{msg: *, code, data, success: boolean}>}
 */
export async function updateDataSystem(paramName, paramValue) {
  const response = await axio.post(getAPIUrl(`/data/system`), { paramName, paramValue });

  if (response.code === 0) {
    return { data: response.data.token, success: true, code: response.code, msg: response.msg }
  }

  return { data: null, success: false, code: response.code, msg: response.msg }
}

/**
 * 获取交易对信息
 * @return {Promise<[{short_close_max: number, position_change_fee_ro: number, long_position_amount: number, short_open_max: number, long_position_mining_reward_ratio: number, long_open_max: number, long_close_max: number, short_position_mining_reward_ratio: number, position_change_fee_ratio: number, token_pair: string, short_position_amount: number, unrealized_profit: number, unrealized_loss: number, position_change_fee_g: number, position_change_fee_k: number}]|*>}
 */
export async function getDataPairs() {
  const content = await axio.get(getAPIUrl(`/data/pairs`));

  if (content && content.data) {
    return content.data;
  }

  return [{
    "token_pair": "ETH/USDT",
    "long_position_mining_reward_ratio": 0.910424455,
    "short_position_mining_reward_ratio": 0.2675805928,
    "long_position_amount": 12631.34712369,
    "short_position_amount": 14325.76157134,
    "long_open_max": 0.353154,
    "short_open_max": 0.25311513,
    "long_close_max": 0.16874342,
    "short_close_max": 0.235436,
    "position_change_fee_ratio": -0.00330014,
    "unrealized_profit": 948.20982945,
    "unrealized_loss": -764.67302865,
    "position_change_fee_k": 10,
    "position_change_fee_g": 30000000,
    "position_change_fee_ro": 0.001
  }];
}

/**
 * 更新交易对信息
 * @param tokenAddr
 * @param paramName
 * @param paramValue
 * @return {Promise<{msg: *, code, data: null, success: boolean}|{msg: *, code, data, success: boolean}>}
 */
export async function updateDataPairs(tokenAddr, paramName, paramValue) {
  const response = await axio.post(getAPIUrl(`/data/pairs`), { tokenAddr, paramName, paramValue });

  if (response.code === 0) {
    return { data: response.data.token, success: true, code: response.code, msg: response.msg }
  }

  return { data: null, success: false, code: response.code, msg: response.msg }
}
