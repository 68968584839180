import React, {ReactNode, useCallback, useEffect, useState} from "react";
import {RouteProps} from "@/router/types";
import {Button, Col, Form, Input, Modal, Row} from "antd";
import {ColumnsType} from "antd/es/table";
import {isPrimeType} from "@/utils/utils";
import {getBrokerEdrfBalance, getDataSystem, updateDataPairs, updateDataSystem} from "@/api/admin";
import {Pagenation} from "@/api/types";
import ErrorMessage from "@/components/ErrorMessage";
import {DerifyTradeModal} from "@/views/CommonViews/ModalTips";

const SystemData = () => {
  const [form] = Form.useForm();
  const [param,setParam] = useState<{title: string, param: string, value?:string,percent:boolean}>({percent: false,title: "", param:"", value: ""});
  const [visible,setVisible] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [systemData,setSystemData] = useState<any>({});

  const showChangeParam = useCallback((param:{title: string,percent:boolean, param: string, value:string}) => {
    setVisible(true);
    setParam(param);
  },[]);

  const doSumit = useCallback((param) => {
    let val = param.value;
    if(param.percent){
      val = (parseFloat(val)/100).toFixed(4)
    }

    updateDataSystem(param.param, val).then((res) => {
      if(res.success){
        setVisible(false);
        DerifyTradeModal.success({msg:"修改成功"});
        loadData();
      }else{
        setErrorMsg(res.msg);
      }
    }).catch(e => {
      setErrorMsg(e);
    }).finally();
  }, []);

  const modifyColumn = (title:string, val:any, percent:boolean, param:string) => {
    let v;
    if(percent){
      v = typeof val === 'number' ? ( isNaN(val) ? '-' : val * 100) : '-';
    }else {
      v = typeof val === 'number' ? ( isNaN(val) ? '-' : val) : '-';
    }
    return (
      <>
        {v}{percent?"%":""}&nbsp;&nbsp;
        <Button type="primary" size={"small"} onClick={() => showChangeParam({
          title,
          param: param,
          percent: percent,
          value: percent? val*100 : val
        })
        }>修改</Button>
      </>
    )
  }

  //TODO充值信息
  const colunmsMap:{[key:string]:ReactNode|{title:ReactNode,render: (v:any,r:any) => ReactNode}} = {
    "liquidity_pool": "流动性池余额（U）：",
    "total_margin_balance": "总保证金余额（U）：",
    "total_unrealized_profit": "总未实现盈利（U）：",
    "total_unrealized_loss": "总未实现亏损（U）：",
    "total_trading_amount": "总交易额（U）：",
    "total_position_amount": "总持仓额（U）：",
    "insurance_pool": "保险池余额（U）：",
    "drf_buyback_pool": "回购基金余额（U）：",
    "position_mining_reward_pool": "挖矿收益池（U）：",
    "broker_reward_pool": "经纪商收益池（U）：",
    "gas_fee_pool": "gas费收益池（U）:",
    "total_usdt": "系统存量资金（U）：",
    "total_edrf": "累积发放eDRF：",
    "total_bdrf": "累积发放bDRF：",
    "trading_fee_ratio": {
      title: "手续费率：",
      render:(val,record) => modifyColumn("手续费率", val, val, "trading_fee_ratio")
    },
    //TODO 持仓挖矿收益u：
    "broker_trading_fee_ratio": {
      title:"经纪商手续费比例：",
      render:(val,record) => modifyColumn("经纪商手续费比例", val, true, "broker_trading_fee_ratio")
    },
    "pmr_trading_fee_ratio": {
      title:"持仓挖矿手续费比例：",
      render:(val,record) => modifyColumn("持仓挖矿手续费比例", val, true, "pmr_trading_fee_ratio")
    },
    "insurance_trading_fee_ratio": {
      title:"保险池手续费比例：",
      render:(val,record) => modifyColumn("保险池手续费比例", val, true, "insurance_trading_fee_ratio")
    },
    //TODO 持仓挖矿收益率的统计周期：
    //TODO 开仓/平仓上限x：
    "margin_maintenance_ratio": {
      title: "维持保证金率",
      render:(val,record) => modifyColumn("维持保证金率", val, true, "margin_maintenance_ratio")
    },
    "margin_liquidation_ratio": {
      title: "自动强平保证金率：",
      render:(val,record) => modifyColumn("自动强平保证金率", val, true, "margin_liquidation_ratio")
    },
    "theta_ratio": "theta_ratio",
    "bond_annual_interest_rate": {
      title: "债券质押收益率 i：",
      render:(val,record) => modifyColumn("债券质押收益率", val, true, "bond_annual_interest_rate")
    },
    "broker_apply_number": {
      title: "经纪商资格eDRF数：",
      render:(val,record) => modifyColumn("经纪商资格eDRF数", val, false, "broker_apply_number")
    },
    "broker_valid_unit_number": {
      title: "经纪商一天续期eDRF数：",
      render:(val,record) => modifyColumn("经纪商一天续期eDRF数", val, false, "broker_valid_unit_number")
    }
  };

  const columns: ColumnsType<any> = [];

  for (let name in colunmsMap) {
    const column:any = colunmsMap[name];

    columns.push({
      title: isPrimeType(column) ? column : column?.title,
      fixed: columns.length === 0 ? "left" : undefined,
      dataIndex: name,
      //width: "10%",
      key: name,
      render: (value, record, index) => isPrimeType(column) ? value : column?.render(value,record,index)
    });
  }

  const [pagenation,setPagenation] = useState<Pagenation>(new Pagenation());
  const [loading, setLoading] = useState(false);

  const loadData = useCallback((page = 1, pageSize = 10) => {
    setLoading(true);
    getDataSystem().then((data) => {
      setSystemData(data);
    }).catch(e => {

    }).finally(() => {
      setLoading(false);
    });
  }, [form]);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Row gutter={[0,20]} justify-content={"center"}>
          {
            columns.map((item, index) => {
              return <Col flex={"30%"} key={index}>
                <Row>
                  <Col  style={{textAlign: "left"}}>{item.title}</Col>
                  <Col  style={{textAlign: "left"}}>{item.render ? item.render(systemData && item.key ? systemData[item.key] : "", systemData, index) : (item.key && systemData? systemData[item.key] : "")}</Col>
                </Row>
              </Col>
            })
          }
      </Row>
      <Modal
        title={param.title}
        getContainer={false}
        visible={visible}
        okText={"确认修改"}
        onOk={() => doSumit(param)}
        onCancel={() => setVisible(false)}
      >
        <ErrorMessage msg={errorMsg} visible={!!errorMsg} onCancel={() => setErrorMsg("")}/>
        <Form>
          <Form.Item label={""}>
            <Input onChange={({target:{value}}) => {
              setParam(Object.assign({}, param, {value}));
            }} value={param.value} name={param.param} suffix={param.percent?"%":""}/>
          </Form.Item>

        </Form>
      </Modal>
    </>
  );
};

export default SystemData;
