import React, {useCallback, useEffect, useState} from "react";
import { RouteProps } from "@/router/types";
import { Switch, Route, Redirect } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "@/store/index";
import Nav from "./nav";
import IntlPro from "@/locales/index";

import "./index.less";
import LoginModal from "@/views/CommonViews/LoginModal";
interface HomeProps extends RouteProps {}
const brokerBindPath = "/broker/bind";
const login = "/login";

const Home: React.FC<HomeProps> = props => {
  const dispatch = useDispatch();
  const { routes, history, location } = props;
  const {isLogin} = useSelector((state:RootStore) => state.user);

  const [redirectPath,setRedirectPath] = useState<string|null>(null);

  const doRedirect = useCallback((path) => {
    history.push(path)
  }, [history]);

  useEffect(() => {
    const rootPath = location.pathname.split("/")[1];
    // eslint-disable-next-line react-hooks/exhaustive-deps

    if(!isLogin && rootPath != "login"){
      history.push("/login")
    }

  }, [location, isLogin]);

  return (
    <>
      <IntlPro>
        <Nav></Nav>
        <div className="main">
          <Switch>
            {routes.map((route, i) => (
              <Route
                path={route.path}
                exact={route.exact}
                key={i}
                render={props => (
                  <route.component {...props} routes={route.routes} />
                )}
              />
            ))}
            {!isLogin  ? <Redirect from="/" to="/login" /> : <Redirect from="/" to="/traders" />}
          </Switch>
        </div>
      </IntlPro>
    </>
  );
};

export default Home;
