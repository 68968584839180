import update from "react-addons-update";
import { createReducer } from "redux-create-reducer";

import Eth from "@/assets/images/Eth.png";
import HECO from "@/assets/images/huobi-token-ht-logo.png";
import Binance from "@/assets/images/binance-coin-bnb-logo.png";
import Solana from "@/assets/images/Solana.png";
import Wallet from "@/assets/images/Metamask.png";
import EnIcon from "@/assets/images/en.png";
import ZhIcon from "@/assets/images/zh.png";
import {Dispatch} from "redux";
import {mergeNonNull} from "@/utils/utils";
import {login} from "@/api/admin";

export class ChainEnum {
  static values : ChainEnum[] = []
  chainId:number;
  name:string;
  logo:string;
  disabled:boolean;

  constructor(chainId:number, name:string, logo = Eth, disabled = true){
    this.chainId = chainId
    this.name = name
    this.logo = logo
    this.disabled = disabled
    ChainEnum.values.push(this)
  }

  static get ETH() {
    return new ChainEnum(1, "mainnet", Eth, true)
  }

  static get Kovan() {
    return new ChainEnum(42, "Kovan", HECO)
  }

  static get Goerli() {
    return new ChainEnum(5, "Goerli", Binance)
  }

  static get Rinkeby() {
    return new ChainEnum(4, "Rinkeby", Eth, false)
  }

  static get Ropsten() {
    return new ChainEnum(3, "Ropsten")
  }

  static get Morden() {
    return new ChainEnum(2, "Morden")
  }
}

const networkMap:{[key:number]:ChainEnum} = {
  1: ChainEnum.ETH,
  2: ChainEnum.Morden,
  3: ChainEnum.Ropsten,
  4: ChainEnum.Rinkeby,
  5: ChainEnum.Goerli,
  42: ChainEnum.Kovan,
  // 1337: "Geth private chains (default)",
}

export class WalletEnum {
  static get MetaMask () {
    return 'MetaMask'
  }
}

/**
 * user wait pop box
 * 0(finished)->1(waiting)->2(success)->0(finished)
 * 0(finished)->1(waiting)->3(failed)->0(finished)
 *
 */
export class UserProcessStatus {
  static get finished() {
    return 0
  }

  static get waiting() {
    return 1
  }

  static get success() {
    return 2
  }

  static get failed() {
    return 3
  }
}

export const mainChain = ChainEnum.Rinkeby

export type UserState = {
  token: string|null,
  username: string|null,
  showLogin: boolean,
  isLogin: boolean
}

const storageToken = sessionStorage.getItem("token");
const state : UserState = {
  token: storageToken || "",
  username:"",
  showLogin: false,
  isLogin: !!storageToken
};


export const reducers = createReducer(state, {
  'user/updateState': (state, {payload})=>{
    return update(state, {$merge: payload})
  }
});

const actions = {
  login (username:string, password:string) {
    return async(commit: Dispatch) => {
      const data = await login(username, password);

      if(data.success){
        sessionStorage.setItem("token", data.data);
        commit({type: "user/updateState", payload: {isLogin: !!data.data, token: data.data, username}})
      }
      return data;
    }
  },
  logout() {
    return async(commit: Dispatch) => {
      commit({type: "user/updateState", payload: {isLogin: false, token: null, username:""}})
      return true;
    }
  },
  loginWallet () {
    return async (commit: Dispatch) => {
    }
  }

}

export default {
  namespace: 'user',
  state,
  reducers,
  actions
}