import React, {ReactNode, useCallback, useEffect, useState} from "react";
import { RouteProps } from "@/router/types";
import {Switch, Route, Redirect, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useQuery} from "@/hooks";
import {Button, Col, Form, Input, Pagination, Row, Select, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {fck, isPrimeType} from "@/utils/utils";
import {type} from "os";
import {isElementOfType} from "react-dom/test-utils";
import {getBrokerList, getBrokerTraders, getExchangeHeldPositions, getExchangeTraders} from "@/api/admin";
import {Pagenation} from "@/api/types";
import {Token} from "@/utils/contractUtil";
interface LoginProps extends RouteProps {}

const BrokerTraders = () => {
  const [form] = Form.useForm();

  const orderByOptions = [
    {value:0, label:"用户数"},
    {value:1, label:"有效用户数"},
    {value:2, label:"经纪商数"},
    {value:3, label:"用户保证金余额之和"},
    {value:4, label:"用户累积交易额"},
    {value:5, label:"用户持仓额之和"},
    {value:6, label:"用户累积挖矿收益之和"},
    {value:7, label:"用户质押DRF数之和"},
    {value:8, label:"用户质押bDRF数之和"}
  ];

  const colunmsMap:{[key:string]:ReactNode|{title:ReactNode,render: (d:any) => ReactNode}} = {
    "broker": "地址",
    //TODO:名称
    "broker_count": "旗下经纪商数量",
    "trader_count": "旗下用户数",
    "valid_trader_count": "旗下有效用户数",
    "traders_total_margin_balance": "旗下用户保证金余额之和（U）",
    "traders_total_trading_amount": "旗下用户累积交易额（U）",
    "traders_total_position_amount": "旗下用户持仓额之和（U）",
    "traders_total_pmr": "旗下用户累积挖矿收益之和（U）",
    "traders_total_staking_drf": "旗下用户质押DRF数之和（DRF）",
    //TODO:旗下用户累积产出eDRF之和（eDRF）
    "traders_total_bdrf_return": "旗下用户质押bDRF之和（bDRF）"
  };

  const columns: ColumnsType<any> = [];

  for (let name in colunmsMap) {
    const column:any = colunmsMap[name];

    columns.push({
      title: isPrimeType(column) ? column : column?.title,
      fixed: columns.length === 0 ? "left" : undefined,
      dataIndex: name,
      //width: "10%",
      key: name,
      render: (value, record, index) => isPrimeType(column) ? value : column?.render(value,record,index)
    });
  }

  const [pagenation,setPagenation] = useState<Pagenation>(new Pagenation());
  const [loading, setLoading] = useState(false);

  const loadData = useCallback((page = 1, pageSize = 10) => {
    const {orderBy, trader} = form.getFieldsValue();
    setLoading(true);
    getBrokerTraders(orderBy||0,trader||"all", page, pageSize).then((data) => {
      data.current = page;
      setPagenation(data);
    }).catch(e => {

    }).finally(() => {
      setLoading(false);
    });
  }, [form]);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Row gutter={[0,20]} style={{flexDirection: "column"}}>
      <Col>
        <Form layout={"inline"} form={form}>
          <Form.Item name="orderBy" label="排序规则">
            <Select defaultValue={0} options={orderByOptions}/>
          </Form.Item>
          <Form.Item name="trader" label="用户地址">
            <Input type={"text"}/>
          </Form.Item>
          <Form.Item>
            <Button type={"primary"} onClick={() => loadData()}>搜索</Button>
          </Form.Item>
        </Form>
      </Col>
      <Col>
        <Table
          loading={loading}
          tableLayout={"fixed"}
          pagination={false}
          dataSource={pagenation.records}
          columns={columns}
        />
      </Col>
      <Col flex={'100%'} style={{textAlign:"right"}}>
        <Pagination
          pageSize={pagenation.pageSize}
          current={pagenation.current}
          onChange={(page: number, pageSize?: number) => {
            loadData(page, pageSize || 10)
          }}
          total={pagenation.totalItems}/>
      </Col>
    </Row>
  );
};

export default BrokerTraders;
