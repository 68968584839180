import React, {ReactNode, useCallback, useEffect, useState} from "react";
import { RouteProps } from "@/router/types";
import {Switch, Route, Redirect, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useQuery} from "@/hooks";
import {Button, Col, Form, Input, Pagination, Row, Select, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {fck, isPrimeType} from "@/utils/utils";
import {type} from "os";
import {isElementOfType} from "react-dom/test-utils";
import {getExchangeHeldPositions, getExchangeTraders} from "@/api/admin";
import {Pagenation} from "@/api/types";
import {Token} from "@/utils/contractUtil";
interface LoginProps extends RouteProps {}

const Positions = () => {
  const [form] = Form.useForm();
  const tokenOptions = [
    {value: "all",label: "全部"},
    {value: Token.BTC,label: "BTC/USDT"},
    {value: Token.ETH,label: "ETH/USDT"}
  ];
  const sideOptions = [
    {value:-1, label:"全部"},
    {value:0 ,label:"多"},
    {value:1 ,label:"空"},
  ];

  const leverageOptions = [
    {value:-1, label:"全部"},
    {value:10 ,label:"10"},
    {value:9 ,label:"9"},
    {value:8 ,label:"8"},
    {value:7 ,label:"7"},
    {value:6 ,label:"6"},
    {value:5 ,label:"5"},
    {value:4 ,label:"4"},
    {value:3 ,label:"3"},
    {value:2 ,label:"2"},
    {value:1 ,label:"1"},
  ];


  const colunmsMap:{[key:string]:ReactNode|{title:ReactNode,render: (d:any) => ReactNode}} = {
    "token_pair": "交易对",
    "side": {
      title: "方向",
      render: (val) => sideOptions.find((item) => item.value === val)?.label
    },
    "leverage": "杠杆",
    "amount": "持仓额（U）",
    "size": "持仓量",
    "unrealized_pnl": "未实现盈亏（U）",
    "average_price": "开仓均价（U）",
    "margin_rate": {
      title: "保证金率",
      render:(val) => <>{fck(val,2,2)}%</>
    },
    "liquidate_price": "强平价格（U）",
    "trader": "用户地址",
  };

  const columns: ColumnsType<any> = [];

  for (let name in colunmsMap) {
    const column:any = colunmsMap[name];

    columns.push({
      title: isPrimeType(column) ? column : column?.title,
      fixed: columns.length === 0 ? "left" : undefined,
      dataIndex: name,
      //width: "10%",
      key: name,
      render: (value, record, index) => isPrimeType(column) ? value : column?.render(value,record,index)
    });
  }

  const [pagenation,setPagenation] = useState<Pagenation>(new Pagenation());
  const [loading, setLoading] = useState(false);

  const loadData = useCallback((page = 1, pageSize = 10) => {
    const {token, side,leverage, trader} = form.getFieldsValue();
    setLoading(true);
    getExchangeHeldPositions(token||'all', side === undefined ? -1 : side,leverage||-1,trader||"all", page, pageSize).then((data) => {
      data.current = page;
      setPagenation(data);
    }).catch(e => {
      console.log(e)
    }).finally(() => {
      setLoading(false);
    });
  }, [form]);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Row gutter={[0,20]} style={{flexDirection: "column"}}>
      <Col>
        <Form layout={"inline"} form={form}>
          <Form.Item name="token" label="交易对">
            <Select defaultValue={'all'} options={tokenOptions}/>
          </Form.Item>
          <Form.Item name="side" label="方向">
            <Select defaultValue={-1} options={sideOptions}/>
          </Form.Item>
          <Form.Item name="leverage" label="杠杆">
            <Select defaultValue={-1} options={leverageOptions}/>
          </Form.Item>
          <Form.Item name="trader" label="用户地址">
            <Input type={"text"}/>
          </Form.Item>
          <Form.Item>
            <Button type={"primary"} onClick={() => loadData()}>搜索</Button>
          </Form.Item>
        </Form>
      </Col>
      <Col>
        <Table
          loading={loading}
          tableLayout={"fixed"}
          pagination={false}
          dataSource={pagenation.records}
          columns={columns}
        />
      </Col>
      <Col flex={'100%'} style={{textAlign:"right"}}>
        <Pagination
          pageSize={pagenation.pageSize}
          current={pagenation.current}
          onChange={(page: number, pageSize?: number) => {
            loadData(page, pageSize || 10)
          }}
          total={pagenation.totalItems}/>
      </Col>
    </Row>
  );
};

export default Positions;
