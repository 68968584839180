
export const CHANGE_LANG = 'CHANGE_LANG'
export const BIND_PARTNERS = 'BIND_PARTNERS'
export const TRANSFER_SHOW = 'TRANSFER_SHOW'
export const UPDATE_APP_STATE = 'UPDATE_APP_STATE'
export const TRIGGER_EVENT = 'triggerEvent'

export declare type ResponseData<T> = {
  msg:string,
  code:string|number,
  success: boolean,
  data: T|null
}


